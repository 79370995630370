import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import actionActions from "../../../context/actions/actions";
import filesActions from "../../../context/files/actions";
import usersActions from "../../../context/users/actions";
import companiesActions from "../../../context/companies/actions";
import categoriesActions from "../../../context/categories/actions";
import internalsActions from "../../../context/internals/actions";
import LayoutWithSidebar from "../../../components/layout/LayoutWithSidebar";
import { notify } from "../../../libraries/notifications";
import actions from "../../../context/transactions/actions";
import { endOfDay, format, isAfter, isBefore, isSameDay, isValid, parse, parseISO, startOfDay } from "date-fns";
import Swal from "sweetalert2";
import Loader from "../../../components/commons/Loader";
import { capitalize, getOwner } from "../../../libraries/utils";
import imagenProfile from '../../../assets/img/profileImg.jpg'
import ComboboxInput from "../../../components/forms/ComboboxInput";
import { Field, Form } from "react-final-form";
import DatepickerInput from "../../../components/forms/DatepickerInput";
import { user } from './../../../context/users/reducers';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Moderador extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      hoy: new Date(),
      loading: true,
      filtro: {
        start_date: new Date(),
        end_date: new Date(),
        target: 0,
        created_by: 0
      },
      users: [],
      transactions: [],
      actions: [],
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t("Actions"), this.t("Moderar")];
    this.titles = this.t("User details");

  }

  handleDateChange = (e) => {
    const dateString = e.target.value;
    const newDate = parse(dateString, 'yyyy-MM-dd', new Date());
    this.setState({ hoy: startOfDay(newDate) });
    // this.filtrarTransactions()
  };
  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    this.setState({ loading: true })
    await this.getCompany()
    await this.getUsers()
    await this.getActions()
    this.setState({ loading: false })
  }

  getCompany = async () => {
    await this.props.onGetCompanies({ owner: getOwner(this.props.auth.user) });
    const { companies } = this.props;
    if (companies.error) {
      notify(this.t(companies.error.message));
    } else {
      if (this.props.companies.items.length) {
        this.setState({ company_id: this.props.companies.items[0].id });
        console.log("company", this.props.companies.items[0].id);
      }
    }
  };

  async getTransactions() {
    const params = {
      "type": "action",
      "owner": this.props.companies.items[0].id,
      // "status": "completed",
      "source": "system",
      // created_at:this.state.hoy
    }
    // console.log(params)
    await this.props.onGetTransactions(params)
    const { transactions } = this.props
    // console.log(transactions, '--trans')
    const error = transactions.error
    if (error) {
      notify(error)
    } else {
      this.setState({ transactions: transactions.items.filter(trx => trx.json_data.points !== 0) },
        () => this.filtrarTransactions()
      );
    }
  }
  async getUsers() {
    const params = {
      "unique_id": this.state.company_id,
      "user_type": "regular"
    }
    console.log(params)
    await this.props.onGetUsers(params)
    const { users } = this.props
    const error = users.error
    if (error) {
      notify(error)
    } else {
      const userOpt = [{ label: `Seleccione un usuario`, value: 0 }, ...users.items.map((u) => ({ label: `${u.json_data.last_name}, ${u.json_data.first_name}`, value: u.id }))]
      this.setState({ users: userOpt });
    }
  }
  async getActions() {
    const params = {
      // "unique_id": this.state.company_id,
      // "user_type": "regular"
    }
    // console.log(params)
    await this.props.onGetActions(params)
    const { actions } = this.props
    const error = actions.error
    if (error) {
      notify(error)
    } else {
      const actionOpt = [{ label: `Seleccione una accion`, value: 0 }, ...actions.items.map((u) => ({ label: `${u.name}`, value: u.id }))]
      this.setState({ actions: actionOpt }, () => this.getTransactions());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Verifica si la fecha ha cambiado
    if (prevState.filtro !== this.state.filtro) {
      this.filtrarTransactions()
    }
  }
  onArchiveTrx = async (id, isArchived, trx) => {
    await this.props.updateTransaction({
      id: id,
      archived: isArchived ? 0 : 1,
      status: isArchived ? "completed" : "archived"
    })
    const error = this.props.transactions.error
    if (error) {
      notify(error)
    } else {
      const transaction = this.props.transaction.item

      this.sendMailUser(transaction, isArchived)
      this.getTransactions()

    }
  }
  confirmArchive = (id, isArchived, trx) => {
    const action = trx.json_data.action;
    const user = trx.json_data.user;

    customSwal.fire({
      title: `¿Estás seguro que deseas ${isArchived ? 'validar' : 'invalidar'}  la accion '${action.name}' del usuario ${user.last_name}, ${user.first_name}?`,
      text: isArchived ? "Esta acción validará la transacción" : "Esta acción invalidará la transacción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: isArchived ? "Validar" : "Invalidar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.onArchiveTrx(id, isArchived, trx)
        customSwal.fire(
          isArchived ? "Validado" : "Invalidado",
          `La transacción ha sido ${isArchived ? "validada" : "invalidada"
          }.`,
          "success"
        );
        if(!isArchived)
          await this.sendMailUser(id);
      }
    })
  }
  sendMailUser = async (trx) => {
    const isArchived = trx.archived
    const action = trx.json_data.action;
    const userId = trx.json_data.user.id;
    const user = this.props.users.items.find(u => u.id === userId);
    console.log({ trx, isArchived });

    let subject = isArchived ? "Novedades sobre tu acción 🤔" : 'Novedades sobre tu reclamo'
    let mailData = {
      template: isArchived ? "invalidate_action" : "validate_action",
      locale: "es",
      to: user?.email,
      first_name: `${user?.json_data.last_name}`,
      last_name: `${user?.json_data.last_name}`,
      params: {
        subject,
        action_name: action.name,
        user_name: `${user?.name}`,
        has_button: true
      }
    }
    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }
  onSubmit = (values) => {
    const { created_by, end_date, start_date, target } = values
    const filtro = {}
    if (start_date) filtro.start_date = startOfDay(new Date(start_date))
    if (end_date) filtro.end_date = startOfDay(new Date(end_date))
    if (created_by) filtro.created_by = created_by
    if (target) filtro.target = target
    this.setState({
      filtro: filtro
    })
  }

  filtrarTransactions = () => {
    const { filtro, transactions } = this.state;
    const filteredTransactions = transactions?.filter(trx => {
      let isMatch = true;
      const transactionDate = new Date(trx.created_at);

      if (filtro.start_date && filtro.end_date) {
        // Filtrar por el rango de fechas
        isMatch = isMatch &&
          isAfter(transactionDate, startOfDay(filtro.start_date)) &&
          isBefore(transactionDate, endOfDay(filtro.end_date));
      } else {
        // Verificar solo start_date
        if (filtro.start_date) {
          const startDate = startOfDay(filtro.start_date);
          // const endDate = endOfDay(filtro.start_date);
          isMatch = isMatch &&
            isAfter(transactionDate, startDate)
          // isBefore(transactionDate, endDate);
        }

        // Verificar solo end_date
        if (filtro.end_date) {
          const endDate = endOfDay(filtro.end_date);
          isMatch = isMatch &&
            isBefore(transactionDate, endDate);
        }
      }
      // Verificar created_by
      if (filtro.created_by) {
        isMatch = isMatch && trx.created_by === filtro.created_by;
      }

      // Verificar target
      if (filtro.target) {
        isMatch = isMatch && trx.target === filtro.target;
      }

      return isMatch;
    });

    this.setState({ transactionsFiltered: filteredTransactions });
  }
  onReset = () => {
    this.setState({
      filtro: {
        target: 0,
        created_by: 0,
        start_date: new Date(),
        end_date: new Date(),
      }
    })
  }

  render() {
    return (
      <LayoutWithSidebar
        main={{ className: "text-content-400" }}
        header={{ breadcrumbs: this.breadcrumbs }}
        container={{ className: "px-8 mb-3 rounded-lg" }}
        actions={actions}
        loading={this.state.loading}
      >
        <div className="flex gap-3 border-gray-500 px-3 py-5  mb-5">

          <Form
            onSubmit={this.onSubmit}
            initialValues={this.state.filtro}
            validate={(values) => {
              const error = {}
              if (!values.start_date || values.start_date == '' || !isValid(values.start_date)) {
                error.start_date = "Fecha invalida"
              }
              return error
            }
            }
          >
            {({
              handleSubmit,
              form
            }) => {
              this.reset = form.reset
              return (
                <form className="flex w-full items-center gap-3 justify-between" onSubmit={handleSubmit}>
                  <Field
                    label={"Fecha Inicio"}
                    placeholder={"Fecha inicio"}
                    name="start_date"
                    component={DatepickerInput}
                    className="w-full md:w-52"
                  />
                  <Field
                    label={"Fecha Fin"}
                    name="end_date"
                    placeholder={"Fecha hasta"}
                    component={DatepickerInput}
                    className="w-full md:w-52"
                  />
                  {<Field
                    label={"Usuario"}
                    name="created_by"
                    placeholder={"Seleccione un usuario"}
                    component={ComboboxInput}
                    options={this.state.users}
                    empty={capitalize(
                      this.t("Selecciona una acción")
                    )}
                    className="w-full md:w-80"
                  />}
                  {<Field
                    label={"Acciones"}
                    name="target"
                    placeholder={"Seleccione una accion"}
                    component={ComboboxInput}
                    options={this.state.actions}
                    empty={capitalize(
                      ("Selecciona una acción")
                    )}
                    className="w-full md:w-80"
                  />}
                  <button type="submit" className="btn btn-primary">Filtrar</button>
                  <button type="button" className="btn btn-primary" onClick={() => this.onReset()}>Reset</button>
                </form>
              )
            }

            }
          </Form>
        </div>
        <div className="flex flex-wrap gap-4">
          {
            this.state.loading && <Loader />
          }
          {this.state?.transactionsFiltered && this.state.transactionsFiltered.length == 0 && <p>No se encontraron Actividades para esa fecha</p>}
          {this.state?.transactions && this.state.transactionsFiltered?.map((trx) => (
            <div key={trx.id} className="flex-shrink-0 w-64 border rounded-lg">
              <div className="w-full p-3 flex flex-col gap-2 items-center border rounded-lg bg-white h-full">
                <div className="flex w-full gap-2 items-center">
                  <div className="w-14 h-12 rounded-lg">
                    <img
                      src={trx?.json_data.user?.profile_image ? trx?.json_data.user?.profile_image : imagenProfile}
                      alt="profile"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>

                  <p className="flex-shrink font-bold">{`${trx?.json_data?.user?.last_name}, ${trx?.json_data?.user?.first_name}`}</p>
                </div>
                <div className="w-full flex gap-2 items-center">
                  {/* <div className="w-14 h-12 rounded-lg">
                    <img
                      src={trx?.json_data.action?.img}
                      alt=""
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div> */}
                  <div>
                    <h4 className="flex-shrink">Realizó la acción: </h4>
                    <p className="flex-shrink">{trx?.json_data?.action?.name}</p>

                  </div>
                </div>
                <div>
                  {trx?.json_data?.description && (
                    <p className="flex-shrink whitespace-pre-line text-left">Descripción: {trx?.json_data?.description}</p>
                  )}
                  <a target="_blank" href={trx?.json_data?.action_img}>

                    <div className="w-full h-48 rounded-lg cursor-pointer">
                      {trx?.json_data?.action_img && (
                        <img
                          src={trx?.json_data?.action_img}
                          alt=""
                          className="w-full h-full object-cover rounded-lg"
                        />
                      )}
                    </div>
                  </a>
                </div>
                <div className="flex w-full items-center justify-between h-8">
                  <span className="text-sm font-bold text-yellow-400">
                    +{trx?.json_data.action?.points}pts
                  </span>
                  <button onClick={() => this.confirmArchive(trx.id, trx.archived, trx)} className={`btn ${trx.archived === 1 ? "btn-success" : "btn-error"} btn-xs`}>
                    {`${trx.archived === 1 ? "Validar" : "Invalidar"} `}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    action: state.actions.current,
    actions: state.actions.list,
    transaction: state.transactions.current,
    transactions: state.transactions.list,
    categories: state.categories.list,
    category: state.categories.current,
    files: state.files,
    users: state.users.list,
    companies: state.companies.list,
    internals: state.internals,
    // rewards: state.users.auth.user.rewards
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrUpdate: (params) => dispatch(actionActions.saveOrUpdate(params)),
    onGetActions: (params) => dispatch(actionActions.getAll(params)),
    onGetRewards: (id) => dispatch(usersActions.getRewards(id)),
    onGetActionTypes: (id) => dispatch(categoriesActions.getAll(id)),
    onGetTypeName: (id) => dispatch(categoriesActions.get(id)),
    onRemove: (id) => dispatch(actionActions.del(id)),
    onGetUsers: (params) => dispatch(usersActions.getAll(params)),
    onClearCurrent: () => dispatch(actionActions.clearCurrent()),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGetTransactions: (params) => dispatch(actions.getAll(params)),
    updateTransaction: (data) => dispatch(actions.saveOrUpdate(data)),
    onGetCompanies: (params) => dispatch(companiesActions.getAll(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Moderador))
