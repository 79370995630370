import { Component, useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import Loader from "../../../components/commons/Loader"
import usersActions from "../../../context/users/actions";
import internalsActions from "../../../context/internals/actions";
import challengesActions from "../../../context/challenges/actions"
import transactionsActions from "../../../context/transactions/actions"

import { notify } from "../../../libraries/notifications";

import Swal from "sweetalert2";

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Activity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      activity: [],
      loading: true,
      ownersActivities: [],
    }
  }

  getActivities = async () => {
    const { id } = this.props.match.params
    await this.props.getActivity(id)
    const filteredActivities = this.props.activity?.filter(activity => activity.json_data?.points !== 0);
    this.setState({ activity: filteredActivities})
    this.setState({ loading: false })
  }

  async componentDidMount() {
    await this.getActivities()
    await this.getOwners()
  }

  getOwners = async  () => {
    const { activity } = this.state
    let owners = []
    activity.forEach(trx => {
      let owner = trx.json_data.user
      if (!owners.find(o => o.id === owner.id)) {
        owners.push({
          id: owner.id,
          first_name: owner.first_name,
          last_name: owner.last_name,
          profile_image: owner.profile_image,
          activities: [trx],
        })
      } else {
        let index = owners.findIndex(o => o.id === owner.id)
        owners[index].activities.push(trx)
      }
    })
    this.setState({ ownersActivities: owners })

  }

  updateTransaction = async (trx) => {
    await this.props.updateTransaction(trx)
  }
  reloadLeaderBoard = async () => {
    const { id } = this.props.match.params
    await this.props.reloadLeaderboard(id)
    // console.log(this.props, 'leaderboard------------')

  }

  sendMailUser = async (trx) => {
    const userId = trx.json_data.user.id;
    const user = this.props.users.items.find(u => u.id === userId);
    const isArchived = trx.archived
    const action = trx.json_data.action;

    
    let subject = isArchived ? "Novedades sobre tu acción 🤔" : 'Novedades sobre tu reclamo'
    let mailData = {
      template: isArchived ? "validate_action"  : "invalidate_action",
      locale: "es",
      to: user?.email,
      first_name: `${trx?.json_data.user.first_name}`,
      last_name: `${trx?.json_data.user.last_name}`,
      params: {
        subject,
        action_name: action.name,
        user_name: `${user?.name}`,
        has_button: true
      }
    }
    try {
      await this.props.onSendMail(mailData);
      console.log('-------------------MANDANDO', mailData)
      
    } catch (error) {
      console.error(error)
    }
    // const internals = this.props.internals;
    // if (internals.error) {
    //   notify(this.t(internals.error.message));
    // }
  }

  render() {
    const { loading, ownersActivities } = this.state

    return (
      <div className="flex flex-col gap-2 min-h-16 divide-y-2 rounded-lg">
        {loading && <Loader />}
        {ownersActivities && !ownersActivities.length && (
          <p className="self-center">Este desafío no tiene actividad todavía.</p>
        )}
        {ownersActivities &&
          ownersActivities.map((owner, i) => {
            return (
              <div className="flex flex-col gap-2 p-2 " key={i} >
                <div className="flex items-center px-4 py-2 gap-2 border-b ">
                  <div className="avatar ">
                    <div className="w-8">
                      <img
                        className="rounded-full"
                        src={owner.profile_image}
                        alt={`Avatar ${owner.first_name}`}
                      />
                    </div>
                  </div>  
                  <h1 >{`${owner.first_name} ${owner.last_name}`}</h1>
                </div>
                
                <div className="w-full py-2 flex flex-1 gap-3 overflow-x-auto ">
                    {owner.activities.map(trx => {
                      if (trx.type === "action")
                        return <ActionActivityCard onReload={() => this.props.onReload()} sendMail={this.sendMailUser} key={trx.id} users={this.props.users} trx={trx} updateTransaction={this.updateTransaction} reloadLeaderBoard={this.reloadLeaderBoard} />
                      if (trx.type === "borrow")
                        return <BorrowActivityCard key={trx.id} trx={trx} />
                      return null
                    })}
                </div>
              </div>
            )
          })}
      </div>
    )
  }
}

const ActionActivityCard = ({ trx, updateTransaction, reloadLeaderBoard, onReload, users, sendMail}) => {
  const [isArchived, setIsArchived] = useState(trx.archived === 1)
  console.log(isArchived)
  const onArchiveTrx = async () => {
    await updateTransaction({
      id: trx.id,
      archived: isArchived ? 0 : 1,
      status:isArchived ? "completed" : "archived"
    })
    await reloadLeaderBoard()
    setIsArchived(!isArchived)
    sendMail(trx)
  }
  const showImage = () => {
    customSwal.fire({
      title: trx.json_data.action.name,
      html: `<img src=${trx.json_data.action_img} alt=${trx.json_data.action.name} class="w-full h-full object-cover" />`,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: false,
    });
  }
  
  const confirmArchive = (id, isArchived, trx) => {
  console.log(isArchived)

    const action = trx.json_data.action;
    const user = trx.json_data.user;
    customSwal.fire({
      title: `¿Estás seguro que deseas ${isArchived ? 'validar' : 'invalidar'}  la accion '${action.name}' del usuario ${user.last_name}, ${user.first_name}?`,
      text: isArchived ? "Esta acción validará la transacción" : "Esta acción invalidará la transacción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: isArchived ? "Validar" : "Invalidar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await onArchiveTrx()
        customSwal.fire(
          isArchived ? "Validado" : "Invalidado",
          `La transacción ha sido ${
            isArchived ? "validada" : "invalidada"
          }.`,
          "success"
        ).then(onReload);  
        if(!isArchived){
          await sendMail(trx)
        }      
      }
    })
  }

  return (
    <div className="flex-shrink-0 w-64  border rounded-lg">
      <div className="w-full p-3 flex flex-col gap-2 items-center border rounded-lg bg-white  h-full">
        <div className="w-full flex gap-2 items-center ">
          <div className="w-14 h-12 rounded-lg">
            <img
              src={trx.json_data.action.img}
              alt=""
              className="w-full h-full object-cover  rounded-lg"
              />
          </div>
          <div>
            <h4 className="flex-shrink">Realizó la acción: </h4>
            <p className="flex-shrink">{trx.json_data.action.name}</p>
          </div>
        </div>
        <div>
         {trx.json_data.description&& <p className="flex-shrink whitespace-pre-line text-left">Descripción: {trx.json_data.description}</p>}
          <div className="w-full h-48 rounded-lg cursor-pointer">
            {trx.json_data?.action_img&&<img
              src={trx.json_data.action_img}
              alt=""
              className="w-full h-full object-cover rounded-lg"
              onClick={() => showImage()}
            />}
          </div>
        </div>
        <div className="flex w-full items-center justify-between h-8">
          <span className="text-sm font-bold text-yellow-400">
            +{trx.json_data.action?.points}pts
          </span>
          <button onClick={() => confirmArchive(trx.id, trx.archived, trx)} className={`btn ${isArchived ? "btn-success" : "btn-error"} btn-xs`}>{`${isArchived ? "Validar" : "Invalidar"} `}</button>
        </div>

      </div>
    </div>
  )
}

// TODO: add archiving
const BorrowActivityCard = ({ trx }) => {
  let type = trx.sub_type === "product" ? "product" : "offer"

  return (
    <div className="flex-shrink-0 w-96 p-3 flex gap-2 items-center rounded-lg bg-white">
      <div className="w-14 h-12 self-start rounded-lg">
        <img
          src={trx.json_data[type].img}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex-1">
        {/* <h6 className='capitalize'>{`${trx.json_data.borrower.first_name} ${trx.json_data.borrower.last_name}`}</h6> */}
        <div className="flex w-full justify-between">
          <p className="max-w-[60ch] flex-shrink">
            Prestó: {trx.json_data[type].name}
          </p>
          <div className="w-6 h-6 p-1 flex-shrink-0 bg-green-800 rounded-full ">
            {/* <Eye className='invert' /> */}
          </div>
        </div>
        <span className="text-sm font-bold text-yellow-400">+{1}pts</span>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    activity: state.challenges.current.activity,
    challenge: state.challenges.current,
    users: state.users.list,
    internals: state.internals,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getActivity: id => dispatch(challengesActions.getActivity(id)),
    updateTransaction: (data) => dispatch(transactionsActions.saveOrUpdate(data)),
    reloadLeaderboard: id => dispatch(challengesActions.getLeaderboard(id)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetUsers: (params) => dispatch(usersActions.getAll(params)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Activity))
)
